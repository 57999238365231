import axios from '@/utils/interceptor'

function get(url, params = [], config = []) {
    return new Promise((resolve, reject) => {
        let extra = {};
        config && (extra = config)
        if (params) {
            // @ts-ignore
            extra.params = params
        }
        axios
            .get(url, extra)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if (err) {
                    reject(err)
                }
            })
    })
}

function post(url, params = []) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

function del(url, params = []) {
    return new Promise((resolve, reject) => {
        axios
            .delete(url, {
                params: params
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

function patch(url, params = []) {
    return new Promise((resolve, reject) => {
        axios
            .patch(url, params)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export {
    get,
    del,
    post,
    patch
}