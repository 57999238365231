<template>
  <header class="m-header">
    <div class="main">
      <div class="left">
        <div class="gdsc">
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220117/GDSC 2022.svg"
            alt=""
            @click="tofrom('/')"
            class="hand"
          />
        </div>
        <div class="iadge">
          <img
            @click="tofrom('https://iadge.org', 1)"
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220117/iadge.svg"
            alt=""
            class="hand"
          />
        </div>
        <div class="lefthree">
          <div>Date:</div>
          <!-- <div>11-13</div> -->
          <div>March - August</div>
          <div>2022</div>
        </div>
      </div>
      <div class="right">
        <div class="inthehome" @click="tofrom('/')">HOME</div>
        <!-- 1223 -->
        <div class="gdsc">
          <!-- slot="reference" -->
          <!-- <div
            class="all-tempalteS hand fontone"
            @click="tofrom('/competition')"
          >
            COMPETITION
          </div>
          <div class="all-tempalteS hand fontone">EXHIBITION</div>
          <div class="hand all-tempalteS fontone" @click="tofrom('/skills')">
            SKILLS
          </div>
          <div class="hand all-tempalteS fontone">PARTICIPATE</div>
          <div class="hand all-tempalteS fontone">VENUE</div> -->

          <div class="hover">GDSC 2022</div>
          <div class="GDSCsize">
            <div
              class="all-tempalteS hand fontone"
              @click="tofrom('/competition')"
            >
              COMPETITION
            </div>
            <!-- <div class="all-tempalteS hand fontone">EXHIBITION</div> -->
            <div class="hand all-tempalteS fontone" @click="tofrom('/skills')">
              SKILLS
            </div>
            <div
              class="hand all-tempalteS fontone"
              @click="tofrom('/Participate')"
            >
              PARTICIPATE
            </div>
            <div class="hand all-tempalteS fontone" @click="tofrom('/venue')">
              VENUE
            </div>
            <div
              class="hand all-tempalteS fontone"
              @click="tofrom('/registration')"
            >
              REGISTRATION
            </div>
          </div>
        </div>

        <div class="organize">
          <!-- slot="reference" -->

          <!-- <div class="hand all-tempalteS fontone">SPONSORS</div>
            <div class="hand all-tempalteS fontone">PARTNERS</div> -->

          <div class="hover">ORGANIZER</div>
          <div class="GDSCsize">
            <div
              class="all-tempalteS hand fontone"
              v-for="(item, index) in originlist"
              :key="index"
              @click="tofrom('/organizer')"
            >
              {{ item.gdsc_organizer_group_type_name }}
            </div>
          </div>
        </div>
        <div style="max-width: 1920px" class="media">
          <!-- 1223 -->
          <!-- slot="reference" -->
          <!-- <div class="all-tempalteS hand fontone" @click="tofrom('/news')">
              NEWS
            </div>
            <div class="hand all-tempalteS fontone" @click="tofrom('/photos')">
              PHOTOS
            </div>
            <div class="hand all-tempalteS fontone">VIDEOS</div>
            <div class="hand all-tempalteS fontone">DOWNLOAD</div> -->

          <div class="all-tempalte hover" id="select">MEDIA</div>
          <div class="GDSCsize">
            <div class="all-tempalteS hand fontone" @click="tofrom('/news')">
              NEWS
            </div>
            <div class="hand all-tempalteS fontone" @click="tofrom('/photos')">
              PHOTOS
            </div>
            <div class="hand all-tempalteS fontone" @click="tofrom('/videos')">
              VIDEOS
            </div>
            <!-- <div class="hand all-tempalteS fontone">DOWNLOAD</div> -->
          </div>
        </div>
        <div
          class="thegetinvoled"
          @click="tofrom('https://iadge.org/Getinvolved', 1)"
        >
          GET INVOLVED
        </div>
        <div class="lastchild">
          <div>ENG</div>
          <div>
            <a href="#search">
              <img
                src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220117/搜索.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="modeil">
        <img
          src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220129/更多.svg"
          alt=""
          @click="drawers()"
        />
      </div>
      <el-drawer
        v-model="drawer2"
        direction="ltr"
        size="80%"
        :with-header="false"
      >
        <template #title>
          <h4>set title by slot</h4>
        </template>
        <template #default>
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            style="border-right: 0px"
          >
            <el-menu-item index="6" @click="tofrom('/')">
              <el-icon><icon-menu /></el-icon>
              <span>HOME</span>
            </el-menu-item>
            <el-sub-menu index="2">
              <template #title>
                <el-icon><location /></el-icon>
                <span>GDSC 2022</span>
              </template>
              <el-menu-item index="1-1" @click="tofrom('/competition')"
                >COMPETITION</el-menu-item
              >
              <!-- <el-menu-item index="1-2">EXHIBITION</el-menu-item> -->
              <el-menu-item index="1-3" @click="tofrom('/skills')"
                >SKILLS</el-menu-item
              >
              <el-menu-item index="1-4" @click="tofrom('/Participate')"
                >PARTICIPATE</el-menu-item
              >
              <el-menu-item index="1-4" @click="tofrom('/venue')"
                >VENUE</el-menu-item
              >
              <el-menu-item index="1-5" @click="tofrom('/registration')"
                >REGISTRATION</el-menu-item
              >
            </el-sub-menu>
            <!-- <el-menu-item index="2">
              <el-icon><icon-menu /></el-icon>
              <span>GDSC 2022</span>
            </el-menu-item> -->
            <el-sub-menu index="3">
              <template #title>
                <el-icon><location /></el-icon>
                <span>ORGANIZER</span>
              </template>
              <el-menu-item
                v-for="(item, index) in originlist"
                :key="index"
                :index="'1' + item.id"
                @click="tofrom('/organizer')"
                >{{ item.gdsc_organizer_group_type_name }}</el-menu-item
              >
              <!-- <el-menu-item index="1-2">SPONSORS</el-menu-item>
              <el-menu-item index="1-3">PARTNERS</el-menu-item> -->
            </el-sub-menu>
            <!-- <el-menu-item index="4">
              <el-icon><setting /></el-icon>
              <span>Navigator Four</span>
            </el-menu-item> -->

            <el-sub-menu index="1">
              <template #title>
                <el-icon><location /></el-icon>
                <span>MEDIA</span>
              </template>
              <el-menu-item index="1-1" @click="tofrom('/news')"
                >NEWS</el-menu-item
              >
              <el-menu-item index="1-2" @click="tofrom('/photos')"
                >PHOTOS</el-menu-item
              >
              <el-menu-item index="1-3" @click="tofrom('/videos')"
                >VIDEOS</el-menu-item
              >
              <!-- <el-menu-item index="1-4">DOWNLOAD</el-menu-item> -->
            </el-sub-menu>

            <el-menu-item index="5">
              <el-icon><setting /></el-icon>
              <span>GET INVOLVED</span>
            </el-menu-item>
          </el-menu>

          <el-divider></el-divider>

          <div
            style="
              color: #000;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <!-- <div style="text-align: center">English</div>
            <div
              style="
                background: #bf0075;
                margin: 0 8px;
                width: 2px;
                height: 20px;
              "
            ></div>
            <div>简体中文</div> -->
          </div>

          <!-- <el-button
            type="primary"
            style="margin-left: 16px"
            @click="drawer = true"
          >
            open
          </el-button>
          <el-button
            type="primary"
            style="margin-left: 16px"
            @click="drawer2 = true"
          >
            with footer
          </el-button> -->
        </template>
        <!-- <template #footer>
          <div style="flex: auto">
            <el-button @click="cancelClick">cancel</el-button>
            <el-button type="primary" @click="confirmClick">confirm</el-button>
          </div>
        </template> -->
      </el-drawer>
    </div>
  </header>
</template>
<script>
import "../utils/render.js";
import { grouptype } from "@/api/api";
export default {
  data() {
    return {
      drawer2: false,
      originlist: [],
      visible: true,
    };
  },
  async mounted() {
    // popur.offsetWidth = ui;
    // let popur = document.getElementsByClassName(".el-popover-all");
    // console.log(ui, popur, 123456);
    const { data } = await grouptype({
      year: "2022",
    });
    // console.log(data, 123);
    this.originlist = data.groupTypeName;
    // let ui = document.querySelector("#select").offsetWidth;
    // document.querySelector(".popur").style.width =
    //   document.querySelector("#select").offsetWidth + "px";
    // console.log( 12345, ui);
  },
  methods: {
    drawers() {
      this.drawer2 = !this.drawer2;
      this.$store.state.show = this.drawer2;
    },
    tofrom(e, a = 0) {
      if (a) {
        window.open(e);
        return;
      }
      // console.log(12,e);
      this.$router.push(e);
    },
    updatewidth() {
      // console.log(ue, ui, 123);
      let ui = document.querySelector("#select").offsetWidth;
      document.querySelector(".popur").style.width = ui + "px";
      document.querySelector(".popurtwo").style.width = ui + "px";
      document.querySelector(".popurthree").style.width = ui + "px";
      // console.log(ui);
    },
  },
};
</script>
<style lang="scss" scope>
@import "../sass/index";
$fontcolor: #bf0075;
.fontone {
  font-size: 15px;
  text-align: center;
  line-height: 54px;
}
.el-popover-all {
  padding: 0px !important;
  border: 0px !important;
  text-align: center;
  inset: -15px auto auto 0px !important;
  border-radius: 0px !important;
  // background-color: rgba($color: #000000, $alpha: 0) !important;
  // width: 96.6% !important;
  // box-shadow: none !important;
  // overflow: hidden;
}
.fonttitlecolor {
  color: $fontcolor;
}
.el-menu-item {
  font-size: 16px;
}
.all-tempalteS {
  &:hover {
    color: #bf0075;
  }
}
.el-overlay {
  top: 96px !important;
}

.m-header {
  overflow: inherit;
  width: 100%;
  color: #fff;
  position: sticky;
  z-index: 8;
  top: 0px;
  .main {
    display: flex;
    // max-height: 120px;
    height: 120px;
    .left {
      box-sizing: border-box;
      // box-sizing: content-box;
      display: flex;
      padding: 14px;
      align-items: center;
      width: 20%;
      background-image: linear-gradient(to right, #6e2d8b, #1b78c2);
      img {
        height: 85px;
        width: 100%;
      }
      .lefthree {
        @include fontstyle(#fff, 14px, 400);
        flex: 1;
        :nth-child(1) {
          margin-bottom: 42px;
        }
      }
      .gdsc {
        flex: 1;
        border-right: 1px solid #fff;
      }
      .iadge {
        flex: 1;
        border-right: 1px solid #fff;
      }
    }
    .modeil {
      display: none;
    }
    .right {
      width: 80%;
      display: flex;
      // background-color: #bf0075;
      align-items: center;
      @include fontstyle(#fff, 18px, bold);
      .inthehome {
        &:hover {
          color: #000;

          background-color: #fff;
        }
      }
      .thegetinvoled {
        &:hover {
          color: #000;

          background-color: #fff;
        }
      }
      .gdsc {
        position: relative;
        display: flex;
        flex-direction: column;
        &:hover {
          .GDSCsize {
            display: block;
            opacity: 1;
            transition: all 1s;
            top: 120px;
            // width: 100%;
          }
          .hover {
            // &:hover + .GDSCsize {
            //   display: block;
            // }
            // &:hover {
            color: #000;

            background-color: #fff;
            // }
          }
        }
        .hover {
          // &:hover + .GDSCsize {
          //   display: block;
          // }
          &:hover {
            color: #000;

            background-color: #fff;
          }
        }
        .GDSCsize {
          position: absolute;
          display: none;
          width: 100%;
          transition: all 1s;
          opacity: 0;
          // right: 0px;
          top: 90px;
          &:hover + .hover {
            background-color: #fff !important;
          }
          .all-tempalte {
            line-height: 120px;
          }
          .all-tempalteS {
            // box-shadow: border-;
            box-sizing: border-box;
            padding: 10px;
            // border: 1px solid #000;
            line-height: 40px;
            height: 60px;
          }
          .all-tempalteS {
            background-color: #fff !important;
            width: 100%;
            font-weight: 400;
            // line-height: 40px;
            color: #000;
            &:hover {
              color: #bf0075;
            }
          }
          // :nth-child(n){
          //   background-color: #fff !important;
          // }
        }
      }
      .organize {
        position: relative;
        display: flex;
        flex-direction: column;
        &:hover {
          .GDSCsize {
            display: block;
            opacity: 1;
            transition: all 1s;
            top: 120px;
          }
          .hover {
            // &:hover + .GDSCsize {
            //   display: block;
            // }
            // &:hover {
            color: #000;
            background-color: #fff;
            // }
          }
        }

        .GDSCsize {
          position: absolute;
          display: none;
          width: 100%;
          // transition: all 1s;
          opacity: 0;
          // right: 0px;
          top: 90px;
          &:hover + .hover {
            background-color: #fff !important;
          }
          .all-tempalte {
            line-height: 120px;
          }
          .all-tempalteS {
            // box-shadow: border-;
            box-sizing: border-box;
            padding: 10px;
            line-height: 40px;
            height: 60px;
            &:nth-child(4) {
              height: 95px;
            }
          }
          .all-tempalteS {
            background-color: #fff !important;
            width: 100%;
            font-weight: 400;
            // line-height: 40px;
            color: #000;
            &:hover {
              color: #bf0075;
            }
          }
          // :nth-child(n){
          //   background-color: #fff !important;
          // }
        }
      }
      .media {
        position: relative;
        display: flex;
        flex-direction: column;
        &:hover {
          .GDSCsize {
            display: block;
            // transition: all 2s;
          }
          .hover {
            // &:hover + .GDSCsize {
            //   display: block;
            // }
            // &:hover {
              color: #000;
              background-color: #fff;
            // }
          }
        }

        .GDSCsize {
          position: absolute;
          display: none;
          width: 100%;
          // right: 0px;
          top: 120px;
          &:hover + .hover {
            background-color: #fff !important;
          }
          .all-tempalte {
            line-height: 120px;
          }
          .all-tempalteS {
            // box-shadow: border-;
            box-sizing: border-box;
            padding: 10px;
            line-height: 40px;
            height: 60px;
          }
          .all-tempalteS {
            background-color: #fff !important;
            width: 100%;
            font-weight: 400;
            // line-height: 40px;
            color: #000;
            &:hover {
              color: #bf0075;
            }
          }
          // :nth-child(n){
          //   background-color: #fff !important;
          // }
        }
      }
      div {
        flex: 1;
        background-color: #bf0075;
        height: 100%;
        line-height: 120px;
        // &:nth-of-type(-n + 5) {
        //   :hover {
        //     background-color: #ffffff;
        //     color: #bf0075;
        //   }
        // }
      }
      .lastchild {
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 120px;
        :nth-child(1) {
          &:hover {
            background-color: #bf0075;
            color: #fff;
          }
        }
        :nth-child(2) {
          &:hover {
            background-color: #bf0075;
            color: #fff !important;
          }
        }

        img {
          width: 14px;
        }
        div:nth-of-type(n) {
          border-left: 1px solid #db6eb1;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .m-header {
    .main {
      display: flex;
      height: 96px;
      .left {
        .lefthree {
          :nth-child(1) {
            margin-bottom: 12px;
          }
        }
        img {
          max-width: 80% !important;
          height: 68px;
        }
        width: 60%;
      }
      .right {
        display: none !important;
      }
      .modeil {
        display: block !important;
        background-color: #bf0075;
        flex: 1;
        text-align: right;
        padding-right: 28px;
        padding-top: 14px;
        line-height: 86px;

        img {
          width: 28px;
        }
      }
    }
  }
}
</style>
