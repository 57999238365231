<template>
  <my-header />
  <div id="app">
    <router-view />
  </div>
  <my-footer />
</template>
<script>
import myFooter from "@/components/myfooter.vue";
import myHeader from "@/components/myheader.vue";
export default {
  components: { myFooter, myHeader },
  created() {},
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // overflow: hidden;
}
body {
  margin: 0px;
  padding: 0px;

}
img {
  max-width: 100%;
  // display: block;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
