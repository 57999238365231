import {
  createRouter,
  createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";

export const routes = [{
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'HOME'
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: 'ABOUT'
    },
  },
  {
    path: "/news",
    name: "news",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/News.vue"),
    meta: {
      title: 'NEWS'
    },
  },
  {
    path: "/newsdetail/:id",
    name: "newsdetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Newsdetail.vue"),
    meta: {
      title: 'NEWSDETAIL'
    },
  },
  {
    path: "/skills",
    name: "skills",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Skills.vue"),
    meta: {
      title: 'SKILLS'
    },
  }, {
    path: "/organizer",
    name: "organizer",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/organizer.vue"),
    meta: {
      title: 'ORGANIZRR'
    },
  },
  {
    path: "/competition",
    name: "competition",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/competition.vue"),
    meta: {
      title: 'COMPETITION'
    },
  },
  {
    path: "/skillsdetail/:id",
    name: "skillsdetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/skillsdetail.vue"),
    meta: {
      title: 'SKILLSDETAIL'
    },
  },
  {
    path: "/photos",
    name: "photos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: 'PHOTOS'
    },
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Photos.vue"),
  },
  {
    path: "/Participate",
    name: "Participate",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: 'PARTICIPATE'
    },
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Participate.vue"),
  },
  {
    path: "/phtosdetail/:id",
    name: "phtossdetail",
    meta: {
      title: 'PHOTOSSDETAIL'
    },
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Photosdetail.vue")
  }, {
    path: "/videos",
    name: "videos",
    meta: {
      title: 'VIDEOS'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Videos.vue"),


  },
  {
    path: "/videosdetail/:id",
    name: "videosdetail",
    meta: {
      title: 'VIDEOSDETAIL'
    },
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/videosdetail.vue")

  },
  {
    path: "/venue",
    name: "venue",
    meta: {
      title: 'VENUE'
    },
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Venue.vue")
  }, {
    path: "/registration",
    name: "registration",
    meta: {
      title: 'REGISTRATION'
    },
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/registrantion.vue")
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.afterEach((to, from, next) => {
  window.document.title = to.meta.title
  // console.log(to, from, next, 1234);
  next()
})

export default router;