import Vue from "vue"
const mixin = [

    // methods: {
    //     tofrom(e) {
    //         this.$router.push(e);
    //     },
    // },
]
export default mixin
// Vue.mixin({mixin})