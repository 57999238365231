<!-- GDSC 首页 -->
<template>
  <div class="home">
    <div class="main">
      <div class="content">
        <div class="all">
          <div>GDSC</div>
          <div>2022</div>
          <div>March - August 2022</div>
          <div>
            The 2nd Global Digital Skills Championships (GDSC 2022) will start
            in mid March 2022! The competition is divided into two stages:
            online selection and on-site finals.
          </div>
        </div>
        <div class="role">
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220613/GDSC2021 电子宣传海报.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="maintwo">
      <div class="content">
        <div
          class="skills hand fontbold"
          style="cursor: pointer"
          @click="tofrom('/skills')"
        >
          Skills
        </div>
        <div class="introdu">
          <div
            v-for="(item, index) in urlse"
            :key="index"
            class="theimage hand"
            @click="tonewspath(item.id, 'skillsdetail')"
          >
            <img :src="item.urls" alt="" />
            <div class="qiu">{{ item.introduce }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mainthree">
      <div class="content">
        <div class="all">
          <div class="skills" style="width: 60%">
            <!-- http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/新闻.jpg -->

            <img
              :src="
                'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                itemlist.image
              "
              alt=""
              class="hand"
              @click="tonewspath(itemlist.id, 'newsdetail')"
            />
          </div>
          <div class="introdu">
            <div class="title">
              <div class="hand" @click="tonewspath(itemlist.id, 'newsdetail')">
                {{ itemlist.title }}
              </div>
              <div>{{ itemlist.creat_time }}</div>
              <div class="bacGDSc">
                <img
                  src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220119/GDSC字体.png"
                  alt=""
                />
              </div>
            </div>
            <div class="nowtitle">
              <div>
                <!-- {{ itemlist.intro }} -->
              </div>
              <!-- <div>{{ itemlist.creat_time }}</div> -->
              <!-- <div>Meet AfriCity, winners of BeChangeMaker Africa 2021</div> -->
              <!-- <div>12. 01. 2022</div> -->
              <div
                class="hand"
                style="cursor: pointer"
                @click="this.$router.push('/news')"
              >
                See all news >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mainfour">
      <div class="content">
        <div class="media fontbold">Media Gallery</div>
        <div class="list">
          <div :class="clickcolor && 'clickcolor'" @click="toswitch()">
            Photo
          </div>
          <div :class="!clickcolor && 'clickcolor'" @click="toswitch()">
            Video
          </div>
        </div>
        <div class="mediagallery" v-show="clickcolor">
          <div class="imageone hand" @click="tonewspaths(photoslist[0]?.id)">
            <img
              :src="
                'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                photoslist[0]?.image
              "
              alt=""
            />
            <!-- <div class="date">{{ photoslist[0]?.gdsc_given_year }}</div> -->
          </div>

          <div class="imagetwo hand">
            <div
              class="overimage toleft"
              @click="tonewspaths(photoslist[1].id)"
            >
              <img
                :src="
                  'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                  photoslist[1]?.image
                "
                alt=""
              />
            </div>

            <div class="overimage hand" @click="tonewspaths(photoslist[2].id)">
              <img
                :src="
                  'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                  photoslist[2]?.image
                "
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="mediagallery" v-show="!clickcolor">
          <div class="imageone" v-for="item in videoslist" :key="item.id">
            <div
              style="border: 10px solid #bf0075"
              class="hand videos"
              @click="tonewspath(item.id, 'videosdetail')"
            >
              <video
                :src="item.video"
                :poster="
                  'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.image
                "
              ></video>
              <div class="Broadcast">
                <img
                  src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220221/播放按钮.png"
                  style="width: 70px"
                  alt=""
                />
              </div>
            </div>

            <div
              style="
                text-align: left;
                font-size: 18px;
                font-weight: 800;
                max-width: 600px;
                margin-top: 10px;
              "
              v-html="item.describe"
            ></div>
          </div>
        </div>
        <div
          class="lastsild hands"
          @click="
            this.clickcolor
              ? this.$router.push('/photos')
              : this.$router.push('/videos')
          "
        >
          {{
            this.clickcolor == true ? " See all photos >" : "See all videos >"
          }}
        </div>
      </div>
    </div>
    <div class="top">
      <div class="content">
        <div class="Organizers">Host & Co-host</div>
        <div class="host">
          <div class="logoimg">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/主办01.svg"
              alt=""
              style="height: 75px"
            />
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/主办02.svg"
              alt=""
              style="height: 75px"
            />
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/主办03.svg"
              alt=""
              style="height: 75px"
            />
          </div>
        </div>

        <div class="Sponsors">Co-organizer</div>
        <div class="logo">
          <div class="triangle" style="cursor: pointer">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/left.svg"
              alt=""
              @click="toptwo(1)"
            />
          </div>
          <div class="logoimg">
            <!-- {{ urlsto }} -->
            <el-carousel
              height="150px"
              arrow="never"
              ref="swipertwoRef"
              autoplay="false"
              interval="30000"
            >
              <el-carousel-item v-for="(item, index) in urlsto" :key="index">
                <img
                  :src="
                    'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                    item.urls0
                  "
                  alt=""
                  v-if="item.urls0"
                />

                <img
                  :src="
                    'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                    item.urls1
                  "
                  v-if="item.urls1"
                  alt=""
                />
                <img
                  :src="
                    'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                    item.urls2
                  "
                  v-if="item.urls2"
                  alt=""
                />
                <img
                  class="fourimage"
                  :src="
                    'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                    item.urls3
                  "
                  v-if="item.urls3"
                  alt=""
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="triangletwo" style="cursor: pointer">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/left.svg"
              alt=""
              @click="toptwo(0)"
            />
          </div>
        </div>

        <div class="Sponsors">Global industry partner</div>
        <div class="logo">
          <div class="triangle" style="cursor: pointer">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/left.svg"
              alt=""
              @click="toptwos(1)"
            />
          </div>
          <div class="logoimg">
            <!-- {{ urlstwo }}111 -->
            <el-carousel
              height="150px"
              arrow="never"
              ref="swipertwoRefs"
              autoplay="false"
              interval="30000"
            >
              <el-carousel-item v-for="(item, index) in urlstwo" :key="index">
                <img
                  :src="
                    'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                    item.urls0
                  "
                  v-if="item.urls0"
                  alt=""
                />

                <img
                  :src="
                    'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                    item.urls1
                  "
                  v-if="item.urls1"
                  alt=""
                />
                <img
                  :src="
                    'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                    item.urls2
                  "
                  alt=""
                  v-if="item.urls2"
                />
                <img
                  class="fourimage"
                  :src="
                    'http://iadge.oss-cn-hangzhou.aliyuncs.com/' +
                    item.urls3
                  "
                  v-if="item.urls3"
                  alt=""
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="triangletwo" style="cursor: pointer">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/left.svg"
              alt=""
              @click="toptwos(0)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newslist, Photostype, details } from "@/api/api";
export default {
  data() {
    return {
      urlse: [
        {
          id: 1,
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/GDSC2022 项目宣传海报 3D数字2(1).jpg",
          introduce: "3D Digital Game Art",
        },
        {
          id: 2,
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/GDSC2022 项目宣传海报 骨骼动画与动捕A(1)(1).jpg",
          introduce: "Animation Technology",
        },
        {
          id: 3,
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/GDSC2022 项目宣传海报 ARVR 竖版_无字(1).jpg",
          introduce: "AR & VR",
        },
      ],
      urlsto: [
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
        },
      ],
      photoslist: [],
      videoslist: [],
      urlstwo: [
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
        },
      ],
      parameter: {
        pageSize: 3,
        page: 1,
        year: 2022,
        groupTypeName: "",
        classify: "photos",
      },
      parameters: {
        pageSize: 8,
        page: 1,
        year: 2022,
        groupTypeName: "",
        classify: "videos",
      },
      clickcolor: true,
      itemlist: "",
    };
  },
  // created(){
  //   console.log(123332434);
  // },
  methods: {
    toswitch() {
      this.clickcolor = !this.clickcolor;
      // console.log(123, this.clickcolor, 121);
    },
    toptwo(e) {
      if (e) {
        this.$refs.swipertwoRef.prev();
      } else {
        this.$refs.swipertwoRef.next();
      }
    },
    toptwos(e) {
      if (e) {
        this.$refs.swipertwoRefs.prev();
      } else {
        this.$refs.swipertwoRefs.next();
      }
    },
    tonewspath(el, path) {
      // console.log(el, path, 123);
      this.$router.push({ name: path, params: { id: el } });
      // console.log(el,123);
    },
    tofrom(e) {
      this.$router.push(e);
    },
    tonews(e) {
      location.href = e;
    },
    tonewspaths(el) {
      this.$router.push({ name: "phtossdetail", params: { id: el } });
      // console.log(el,123);
    },
  },
  mounted() {
    newslist({
      ...this.parameter,
    }).then((res) => {
      this.itemlist = res.data.msgList.list[0];
      // console.log(this.itemlist, 123455);
    });
    details({
      year: "2022",
    }).then((res) => {
      // console.log(this.originlist, res.data["CO-ORGANIZER"], 123);
      const newArr = [];
      const newArrtwo = [];
      const size = 4;
      for (
        var i = 0;
        i <= Math.ceil(res.data["CO-ORGANIZER"].length + 1 / size);
        i++
      ) {
        const r = res.data["CO-ORGANIZER"].splice(0, size).map((v) => v.logo);
        const obj = {};
        r.forEach((v, i) => (obj["urls" + i] = v));
        newArr.push(obj);
      }

      for (
        var y = 0;
        y <= Math.ceil(res.data["GLOBAL INDUSTRY PARTNER"].length + 1 / size);
        y++
      ) {
        const r = res.data["GLOBAL INDUSTRY PARTNER"]
          .splice(0, size)
          .map((v) => v.logo);
        const obj = {};
        r.forEach((v, i) => (obj["urls" + i] = v));
        newArrtwo.push(obj);
      }
      // console.log(newArr, 124);
      this.urlstwo = newArrtwo;
      this.urlsto = newArr;

      // res.data["CO-ORGANIZER"].map((e, arr) => {
      //   console.log(e, arr, 124);
      // });
    });
    Photostype({
      ...this.parameters,
    }).then((res) => {
      this.videoslist = res.data.msgList.list.splice(0, 2);
    });
    Photostype({
      ...this.parameter,
    }).then((res) => {
      this.photoslist = res.data.msgList.list;

      // this.photoslist.push(res.data.msgList.list);
      // console.log(this.photoslist[1], 45672);

      // this.pages = res.data.msgList.pages;
    });
  },
};
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// export default {
//   name: "Home",
//   components: {
//     HelloWorld,
//   },
// };
</script>
<style lang="scss" scoped>
@import "../sass/index";

.top {
  background-color: #f7f7f7;

  .Organizers {
    padding: 51px 0 84px 0px;
    @include fontstyle(#000, 30px, bold, auto);
  }

  .content {
    width: 1200px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .host {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    :nth-child(1) {
      margin-right: 25px;
    }

    :nth-child(2) {
      margin-right: 25px;
    }
  }

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .triangle {
      img {
        width: 10px;
      }
    }

    .triangletwo {
      img {
        width: 10px;
      }

      transform: rotate(180deg);
    }

    .logoimg {
      display: flex;
      //   justify-content: center;
      align-items: center;

      .el-carousel {
        width: 1040px;
      }

      // width: 100px;
      // :nth-child(-n + 2) {
      //   margin-right: 40px;
      // }
      img {
        width: 204px;
        // height: fit-content;
      }
    }
  }

  .Sponsors {
    padding: 84px 0 84px 0px;
    @include fontstyle(#000, 30px, bold, auto);
  }

  .silder {
    max-width: 100%;
  }
}

.clickcolor {
  background-color: #bf0075;
  color: #fff !important;
}

.imageone {
  position: relative;

  .Broadcast {
    position: absolute;
    top: 37%;
    right: 44%;
  }
}

.imagetwo {
  position: relative;

  .Broadcast {
    position: absolute;
    top: 40%;
    right: 40%;
  }
}

.home {
  .main {
    height: 80vh;
    display: flex;
    align-items: center;
    background-image: url(http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220207/首页（媒体为视频版）banner.png);
    background-repeat: no-repeat;
    background-size: cover;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1200px;
      @include maxwidth;
      margin: 0 auto;

      .all {
        max-width: 354px;
        margin-right: 22px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        :nth-child(1) {
          @include fontstyle(#fff, 80px, bold, auto);
        }

        :nth-child(2) {
          @include fontstyle(#fff, 80px, bold, auto);
        }

        :nth-child(3) {
          margin-bottom: 30px;
          @include fontstyle(#1b78c2, 45px, bold, auto);
        }

        :nth-child(4) {
          @include fontstyle(#fff, 18px, 400, auto);
        }
      }
    }
  }

  div.maintwo {
    width: 1200px;
    @include maxwidth;
    margin: 120px auto;

    div.content {
      display: flex;
      flex-direction: column;

      .skills {
        @include fontstyle(#bf0075, 80px, bold, auto);
        margin-bottom: 40px;
      }

      .introdu {
        display: flex;
        justify-content: center;

        // align-items: center;
        :last-child {
          margin-right: 0px !important;
        }

        :nth-of-type(-n + 3) {
          margin-right: 45px;
          overflow: hidden;
        }

        .theimage {
          position: relative;

          // over
          //  margin-right: 100px;
          // width: 100%;
          &:hover {
            .qiu {
              opacity: 1;
            }
          }

          .qiu {
            width: 100%;
            position: absolute;
            height: 120px;
            @include fontstyle(#fff, 24px, bold, auto);
            line-height: 120px;
            background: linear-gradient(90deg, #6e2d8b 0%, #1b78c2 100%);
            text-align: center;
            bottom: 0;
            opacity: 0;
            transition: 1s;
            // overflow: hidden;
          }
        }
      }

      img {
        display: block;
      }
    }
  }

  div.mainthree {
    height: 68vh;
    background-image: url(http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/底色.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    display: flex;
    align-items: center;

    .content {
      width: 1200px;
      @include maxwidth;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;

      .all {
        display: flex;

        // align-items: center;
        // margin-right: 20px;
        .introdu {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          // align-content: space-around;
          .title {
            background-color: #bf0075;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;

            .bacGDSc {
              position: absolute;
              bottom: -130px;
              right: 0px;
              // opacity: 0.2;
              @include fontstyle(#fff, 118px, bold, auto);

              img {
                // width: 80px;
              }
            }

            :nth-child(1) {
              text-align: left;
              @include fontstyle(#fff, 30px, bold, pointer);
              margin-bottom: 101px;

              &:hover {
                text-decoration: underline;
              }
            }

            :nth-child(2) {
              text-align: left;
              @include fontstyle(#fff, 14px, 400, auto);
            }
          }

          .nowtitle {
            padding: 30px 30px 5px 30px;

            :nth-child(1) {
              @include fontstyle(#fff, 18px, 400, auto);
              text-decoration: underline;
              text-align: left;
              margin-bottom: 18px;
            }

            :nth-child(2) {
              @include fontstyle(#fff, 14px, 400, auto);
              text-align: left;
              margin-bottom: 18px;
            }

            :nth-child(3) {
              @include fontstyle(#fff, 18px, 400, auto);
              text-align: left;
              margin-bottom: 18px;
            }

            :nth-child(4) {
              @include fontstyle(#fff, 14px, 400, auto);
              text-align: left;
              margin-bottom: 18px;
            }

            :nth-child(5) {
              @include fontstyle(#fff, 16px, bold, auto);
              text-align: right;
              // margin-bottom: 18px;
            }
          }
        }
      }
    }
  }

  .mainfour {
    width: 1200px;
    @include maxwidth;
    margin: 120px auto 60px;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .media {
        @include fontstyle(#1b78c2, 80px, bold, auto);
        margin-bottom: 25px;
      }

      .list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        margin-right: 10px;

        :nth-child(n) {
          width: 80px;
          height: 40px;
          line-height: 40px;
          @include fontstyle(#000, 16px, 400);

          &:hover {
            background-color: #bf0075;
            color: #fff;
            transition: 0.5s;
          }
        }
      }

      .lastsild {
        @include fontstyle(#bf0075, 16px, bold);
      }

      .mediagallery {
        display: flex;
        align-items: inherit;
        justify-content: center;
        margin-bottom: 42px;

        .imageone {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          img {
            height: 100%;
          }

          .videos {
            height: 300px;
            overflow: hidden;
            margin: 0px;
          }

          // max-width: 90%;
          flex: 1;
          position: relative;

          &:nth-child(1) {
            margin-right: 20px;
          }

          video {
            max-width: 600px;
            vertical-align: top;
            width: 100%;
          }

          // img {
          //   width: 98%;
          // }
          .date {
            position: absolute;
            bottom: 4px;
            // left: 5px;
            width: 110px;
            height: 69px;
            line-height: 69px;
            background-color: #1b78c2;
            @include fontstyle(#fff, 30px, bold);
          }
        }

        .imagetwo {
          overflow: hidden;
          max-height: 334.6px;
          box-sizing: border-box;
          max-width: 50%;
          display: flex;
          // flex: 1;
          align-items: center;

          // margin-left: 14px;
          .toleft {
            margin-right: 20px;
          }

          .overimage {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            flex: 1;

            //  width: 50%;
            img {
              // height: 200%;
              max-width: 201%;
            }

            //  width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .top {
    .content {
      width: auto !important;
    }

    .triangle {
    }

    .logoimg {
      .el-carousel {
        width: 700px !important;
      }

      .fourimage {
        display: none;
      }

      // flex: 1;
      // width: 800px !important;
      // :nth-child(n) {
      //   flex: 1;
      // }
      img {
        width: 200px;
      }

      .tomini {
        display: none;
      }
    }
  }

  .main {
    background-position: 64% 35%;

    .content {
      width: auto !important;
    }
  }

  .maintwo {
    width: auto !important;
    margin: 40px 40px 80px 40px !important;
  }

  .mainthree {
    height: auto !important;

    .content {
      width: auto !important;
      margin: 61px auto !important;
      flex-direction: column !important;

      .all {
        margin: 0 28px !important;
        flex-direction: column !important;

        .skills {
          width: 100% !important;
        }

        img {
          width: 100%;
          display: block;
        }

        .introdu {
          .title {
            .bacGDSc {
              // position: absolute;
              bottom: -100px !important;
            }
          }

          .nowtitle {
            background-color: #fff;

            :nth-child(1) {
              color: #bf0075 !important;
              font-weight: bold !important;
              text-decoration: none !important;
            }

            :nth-child(2) {
              color: #000 !important;
              margin-bottom: 30px !important;
            }

            :nth-child(3) {
              color: #bf0075 !important;
              font-weight: bold !important;
              text-decoration: none !important;
            }

            :nth-child(4) {
              color: #000 !important;
              margin-bottom: 30px !important;
            }

            :nth-child(5) {
              text-align: center !important;
              max-width: 480px;
              background-color: #1b78c2;
              width: 100%;
              height: 70px !important;
              line-height: 70px;
              margin-bottom: 36px;
              cursor: pointer !important;
            }
          }
        }
      }
    }
  }

  .mainfour {
    width: auto !important;
    margin: 61px auto !important;

    .content {
      margin: 0px 20px !important;

      .media {
        font-size: 50px !important;
      }

      .mediagallery {
        flex-direction: column !important;

        align-items: center !important;

        .imageone {
          .videos {
            height: auto !important;
          }

          margin-right: 0px !important;

          img {
            width: 100%;
            display: block;
            // margin-bottom: 23px;
          }

          .date {
            left: 0 !important;
            bottom: 0 !important;
          }

          .Broadcast {
            top: 30%;
            right: 40%;
          }
        }

        .imagetwo {
          flex-direction: column !important;
          max-width: 100% !important;
          margin-left: 0px !important;
          max-height: none !important;

          .toleft {
            margin: 20px 0px !important;
          }

          .toleft {
            margin-right: 0px !important;
          }

          .Broadcast {
            top: 30%;
            right: 40%;
          }

          img {
            width: 100%;
            // margin-bottom: 23px;
          }
        }
      }

      .lastsild {
        margin-top: 20px;
        text-align: center !important;
        // max-width: 480px;
        background-color: #bf0075;
        color: #fff !important;
        width: 100%;
        margin-bottom: 0px !important;
        height: 78px !important;
        line-height: 78px;
        margin-bottom: 36px;
        cursor: pointer !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .top {
    padding: 20px;

    .host {
      .logoimg {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 80px;
        }
      }
    }

    .logo {
      .triangle {
      }

      .logoimg {
        .el-carousel {
          width: 320px !important;
        }

        img {
          height: auto !important;
          width: 80px !important;
        }

        :nth-child(-n + 2) {
          margin-right: 1px !important;
        }

        .tomini {
        }
      }
    }
  }

  .home {
    .main {
      height: 100vh;
    }
  }

  .main {
    // height: 100vh !important;
    // background-image: url("http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220217/banner2022手机版(2).jpg") !;
    .content {
      flex-direction: column !important;

      .all {
        margin-right: 0px !important;

        :nth-child(1) {
          font-size: 50px !important;
        }

        :nth-child(2) {
          font-size: 50px !important;
          margin-bottom: 24px;
        }

        :nth-child(3) {
          font-size: 22px !important;
          margin-bottom: 15px !important;
        }

        :nth-child(4) {
          font-size: 18px !important;
          line-height: 36px !important;
        }
      }

      .role {
        margin: 30px 0 43px 0px;

        img {
          max-width: 100%;
        }
      }
    }
  }

  div.maintwo {
    width: auto !important;
    // flex-direction: column !important;
    margin: 42px 26px !important;

    .content {
      .skills {
        font-size: 40px !important;
      }

      .introdu {
        flex-direction: column !important;

        img {
          width: 100%;
        }

        :nth-of-type(-n + 3) {
          margin-right: 0px !important;
          overflow: hidden;
          margin-bottom: 23px;
        }
      }
    }
  }

  div.mainthree {
    .content {
      .all {
        .introdu {
          .title {
            .bacGDSc {
              width: 80%;
              bottom: -25px !important;
            }

            :nth-child(1) {
              font-size: 26px !important;
              margin-bottom: 25px !important;
            }
          }
        }
      }
    }
  }
}
</style>
