<template>
  <div class="m-footer">
    <div class="main">
      <div class="boton">
        <div class="botoneach">
          <div class="gdscrouter">
            <div>GDSC 2022</div>
            <div @click="tofrom('/')">Home</div>
            <div @click="tofrom('/competition')">Competition</div>
            <!-- <div>Exhibition</div> -->
            <div @click="tofrom('/skills')">Skills</div>
            <div @click="tofrom('/Participate')">Participate</div>
            <div @click="tofrom('/venue')">Venue</div>
          </div>
          <div class="gdschost">
            <div>Organizer</div>
            <div
              v-for="(item, index) in originlist"
              :key="index"
              @click="tofrom('/organizer')"
            >
              {{ item }}
            </div>
          </div>
          <div class="gdscmedia">
            <div>Media</div>
            <div @click="tofrom('/news')">News</div>
            <div @click="tofrom('/photos')">Photos</div>
            <div @click="tofrom('/videos')">Videos</div>
            <!-- <div>Download</div> -->
          </div>
          <div class="gdscsoclal">
            <div>Social</div>
            <div>Weibo</div>
            <div>Wechat</div>
            <div>Facebook</div>
            <div>Twitter</div>
            <div>Youtube</div>
            <div>Instagram</div>
          </div>
          <div class="search">
            <div>Search</div>
            <div class="searchvalue">
              <div class="e-input">
                <el-input
                  v-model="input"
                  @change="tofrom('/' + input)"
                  placeholder="Search GDSC 2022"
                />
              </div>
              <div class="e-button" id="search">
                <el-button @click="tofrom('/' + input)">Search</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="botoneachbam">
          <div class="beian">©2022 GDSC 2022 Privacy Terms Contact</div>
          <div class="">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220117/GDSC 2022.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "@/common/mixin";
import { routes } from "../router/index";
import { grouptype, details } from "@/api/api";
export default {
  mixins: [mixin],
  data() {
    return {
      input: "",
      originlist: [],
      urls: [
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
        },
        {
          urls: "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助4.svg",
          urls1:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/主办1.svg",
          urls2:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助2.svg",
          urls3:
            "http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220118/赞助3.svg",
        },
      ],
    };
  },
  async mounted() {
    const { data } = await grouptype({
      year: "2022",
    });
    // console.log(data, 123);
    this.originlist = data.groupTypeName.map((e, f, g) => {
      var string = e.gdsc_organizer_group_type_name.slice(0, 1);
      return (
        string.toUpperCase() +
        e.gdsc_organizer_group_type_name.substring(1).toLowerCase()
      );
    });

    details({
      year: "2022",
    }).then((res) => {
      const newArr = [];
      const size = 4;
      for (
        var i = 0;
        i <= Math.ceil(res.data["CO-ORGANIZER"].length + 1 / size);
        i++
      ) {
        const r = res.data["CO-ORGANIZER"].splice(0, size).map((v) => v.logo);
        const obj = {};
        r.forEach((v, i) => (obj["urls" + i] = v));
        newArr.push(obj);
      }
      console.log(newArr, 124);
      this.urls = newArr;
    });
  },
  methods: {
    topone(e) {
      if (e) {
        this.$refs.swiperRef.prev();
      } else {
        this.$refs.swiperRef.next();
      }
    },
    tofrom(rute) {
      let find = routes.find((e) => {
        return e.path == rute;
      });
      (find && this.$router.push(rute)) || this.$message("未搜索到相关内容");
    },
    toptwo(e) {
      if (e) {
        this.$refs.swipertwoRef.prev();
      } else {
        this.$refs.swipertwoRef.next();
      }
    },
  },
};
</script>
<style lang="scss">
@import "../sass/index";

// @import "../sass/style.css";
.el-carousel__item {
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  // img {
  //   // flex: 1;
  //   width: 22% !important;
  // }
}

.el-carousel__indicators {
  display: none;
}

.m-footer {
  background-image: linear-gradient(to right, #6e2d8b, #1b78c2);

  .main {
    width: 1200px;
    @include maxwidth;
    margin: 0 auto;

    .boton {
      padding-top: 100px;

      .botoneach {
        display: flex;
        justify-content: space-between;

        .gdscrouter {
          :nth-child(1) {
            text-align: left;
            margin-bottom: 32px;
            @include fontstyle(#fff, 20px, bold, auto);
          }

          :nth-child(n + 2) {
            margin-bottom: 14px;
            text-align: left;
            @include fontstyle(#fff, 16px, 400);
          }
        }

        .gdschost {
          :nth-child(1) {
            text-align: left;
            margin-bottom: 32px;
            @include fontstyle(#fff, 20px, bold, auto);
          }

          :nth-child(n + 2) {
            margin-bottom: 14px;
            text-align: left;
            @include fontstyle(#fff, 16px, 400);
          }
        }

        .gdscmedia {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          :nth-child(1) {
            margin-bottom: 32px;
            @include fontstyle(#fff, 20px, bold, auto);
          }

          :nth-child(n + 2) {
            margin-bottom: 14px;
            text-align: left;
            @include fontstyle(#fff, 16px, 400);
          }
        }

        .gdscsoclal {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          :nth-child(2) {
            &::before {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              background: url("http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220129/新浪微博.svg")
                no-repeat;
              background-size: 12px 22px;
              background-size: 100%;
              margin: 0 8px 8px 0;
            }
          }

          :nth-child(3) {
            display: flex;

            &::before {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              background: url("http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220129/wechat.svg")
                no-repeat;
              background-size: 12px 22px;
              background-size: 100%;
              margin: 0 8px 8px 0;
            }
          }

          :nth-child(4) {
            display: flex;

            &::before {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              background: url("http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220129/脸书.svg")
                no-repeat;
              background-size: 12px 22px;
              background-size: 100%;
              margin: 0 8px 8px 0;
            }
          }

          :nth-child(5) {
            display: flex;

            &::before {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              background: url("http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220129/推特.svg")
                no-repeat;
              background-size: 12px 22px;
              background-size: 100%;
              margin: 0 8px 8px 0;
            }
          }

          :nth-child(6) {
            display: flex;

            &::before {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              background: url("http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220129/Youtube-fill.svg")
                no-repeat;
              background-size: 12px 22px;
              background-size: 100%;
              margin: 0 8px 8px 0;
            }
          }

          :nth-child(7) {
            display: flex;

            &::before {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              background: url("http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220129/instagram.svg")
                no-repeat;
              background-size: 12px 22px;
              background-size: 100%;
              margin: 0 8px 8px 0;
            }
          }

          :nth-child(1) {
            margin-bottom: 32px;
            @include fontstyle(#fff, 20px, bold, auto);
          }

          :nth-child(n + 2) {
            display: flex;
            margin-bottom: 14px;
            text-align: left;
            @include fontstyle(#fff, 16px, 400);
          }
        }

        .search {
          // flex:1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .searchvalue {
            display: flex;
          }

          div:nth-of-type(1) {
            margin-bottom: 32px;
            @include fontstyle(#fff, 20px, bold, auto);
          }

          :nth-child(n + 2) {
            margin-bottom: 14px;
            text-align: left;
            @include fontstyle(#fff, 16px, 400);
          }

          .e-input {
            .el-input__inner {
              text-align: center !important;
              padding: 0 20px;
              width: 230px;
              //   color: #222 !important;
            }
          }

          .e-button {
            .el-button {
              width: 120px;
              margin-left: 17px;
              background-color: #d8007f;
              border: 0;
              @include fontstyle(#fff, 16px, bold);
            }
          }
        }
      }

      .botoneachbam {
        display: flex;
        color: #fff;
        justify-content: space-between;
        padding-bottom: 35px;

        :nth-child(1) {
          align-self: flex-end;
        }

        :nth-child(2) {
          img {
            width: 94px;
          }
        }
      }
    }

    .top {
      .Organizers {
        padding: 51px 0 84px 0px;
        @include fontstyle(#fff, 30px, bold, auto);
      }

      .host {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        :nth-child(1) {
          margin-right: 25px;
        }
      }

      .logo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .triangle {
          img {
            width: 10px;
          }
        }

        .triangletwo {
          img {
            width: 10px;
          }

          transform: rotate(180deg);
        }

        .logoimg {
          display: flex;
          //   justify-content: center;
          align-items: center;

          .el-carousel {
            width: 1040px;
          }

          // width: 100px;
          // :nth-child(-n + 2) {
          //   margin-right: 40px;
          // }
          img {
            width: 204px;
            // height: fit-content;
          }
        }
      }

      .Sponsors {
        padding: 84px 0 84px 0px;
        @include fontstyle(#fff, 30px, bold, auto);
      }

      .silder {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    .top {
      padding: 20px;

      .host {
        .logoimg {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 80px;
          }
        }
      }

      .logo {
        .triangle {
        }

        .logoimg {
          .el-carousel {
            width: 320px !important;
          }

          img {
            height: auto !important;
            width: 80px !important;
          }

          :nth-child(-n + 2) {
            margin-right: 1px !important;
          }

          .tomini {
          }
        }
      }
    }
  }

  .botoneachbam {
    flex-direction: column-reverse;
    padding: 20px;
    align-items: flex-start;

    .beian {
      align-self: flex-start !important;
      margin-top: 20px;
    }

    // :nth-child(2) {

    // }
  }

  .search {
    max-width: 100%;

    .searchvalue {
      display: flex;
      // justify-content:;
    }

    .e-input {
      .el-input__inner {
        text-align: center !important;
        padding: 0 20px;
        width: 200px !important;
        //   color: #222 !important;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .main {
    width: auto !important;

    .top {
      .triangle {
      }

      .logoimg {
        .el-carousel {
          width: 700px !important;
        }

        .fourimage {
          display: none;
        }

        // flex: 1;
        // width: 800px !important;
        // :nth-child(n) {
        //   flex: 1;
        // }
        img {
          width: 200px;
        }

        .tomini {
          display: none;
        }
      }
    }

    .boton {
      .botoneach {
        padding: 20px;
        flex-direction: column !important;

        .search {
          order: -1;
        }

        .gdscrouter {
          :nth-child(1) {
            text-align: left;
          }

          :last-child {
            margin-bottom: 52px !important;
          }
        }

        .gdschost {
          :nth-child(1) {
            text-align: left;
          }

          :last-child {
            margin-bottom: 52px !important;
          }
        }

        .gdscmedia {
          :nth-child(1) {
            text-align: left;
          }

          :last-child {
            margin-bottom: 52px !important;
          }
        }
      }
    }
  }
}
</style>
