import {
    createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import 'normalize.css/normalize.css'
import "@/sass/index.scss";
// 引入特殊字体
import './assets/font.css'
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})
// router.beforeEach((to, from, next) => {
//     // 让页面回到顶部
//     document.documentElement.scrollTop = 0
//     // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
//     next()
// })
createApp(App).use(store).use(router).use(ElementPlus).mount("#app");