import axios from "axios";
// import { getCookie } from "@/utils/util";
// import { Toast } from "vant";


// 取消重复请求
// const pending: Array<PendingType> = [];
// const CancelToken = axios.CancelToken;
// axios 实例
const instance = axios.create({
  timeout: 30000,
  baseURL:
    // process.env.NODE_ENV === "development"
    //   ? process.env.VUE_APP_APID
    //   : process.env.VUE_APP_API,
  process.env.VUE_APP_API,
});

// let loadingInstance;
// 添加请求拦截器
instance.interceptors.request.use(
  (request) => {
    request.headers["accept"] = "application/json";
    request.headers["Content-Type"] = "application/json";
    // let token = getCookie("token") || localStorage.getItem("token");
    // request.headers["Authorization"] = "Bearer " + token;
    // if (!request.headers.toastN) {
    //   loadingInstance = Toast.loading({
    //     message: "LOADING...",
    //     icon:
    //       "https://meshmellow.oss-cn-hangzhou.aliyuncs.com/jojo/utils/waiting.gif",
    //     forbidClick: false,
    //     duration: 0,
    //   });
    // }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // Toast.clear();
    return response;
  },
  (error) => {
    const response = error.response;
    // Toast.clear();
    // 根据返回的http状态码做不同的处理
    if (!response.config.headers.nr) {
      switch (response?.status) {
        case 401:
          // localStorage.removeItem("token");
          // location.href = `http://account.meshmellow.cn/mlogin?redirect=${location.href}`;
          break;
        default:
          break;
      }
    }
    return Promise.reject(response);
  }
);

export default instance;
