// 引入请求的方式
import {
    get,
    post,
    del,
    patch
} from './twice'
// 新闻分类查询
export const newstype = (data) => post('/gdsc_news/group_type', data)
// 新闻列表,带有分页  参数
// ①year                        活动指定年份（如：２０１８／２０２２等指定年份）
// ②groupTypeName        新闻分类名称（/gdsc_news/group_type接口反馈回来的gdsc_group_type_name；如果是所有的此参数留空即可）
// ③page                        页面（如１，２，３等）
// ④pageSize                显示数量
export const newslist = (data) => post('/gdsc_news/new_list', data)
// 新闻详细查询    参数id
export const newsdetail = (data) => post('/gdsc_news/details', data)
// gdsc-skills接口
// ①year                        活动指定年份（如：２０１８／２０２２等指定年份）
// ②page                        页面（如１，２，３等）
// ③pageSize                显示数量
export const skillnews = (data) => post('/gdsc_skill/new_list', data)
// skills新闻详细内容     参数id
export const skilldetail = (data) => post('/gdsc_skill/details', data)
// gdsc相册接口
export const Photoalbum = (data) => post('/gdsc_photo/group_type', data)
// 相册分页
// ①year                        活动指定年份（如：２０１８／２０２２等指定年份）
// ②groupTypeName       PHOTOS分类名称（/gdsc_photo/group_type接口反馈回来的gdsc_photos_group_type_name；如果是所有的此参数留空即可）
// ③page                        页面（如１，２，３等）
// ④pageSize                显示数量
export const Photostype = (data) => post('/gdsc_photo/new_list', data)
// 相册详情   参数：id                新闻Id（/gdsc_photo/new_list接口反馈回来的ｉｄ参数）
export const Photosdetail = (data) => post('/gdsc_photo/details', data)
// GDSC-新闻新增intro(简介)字段。因与婷婷沟通。NEWS/SKILLS/PHOTO正文有可能需要会有字体加粗等操作，所以正文再后台使用的是富文本编辑器。再写页面的时候注意下这个问题
// 新增GDSC-NEWS 详情页中推荐(more)中显示的内容查询接口：
/* 
①year                         活动指定年份（如：２０１８／２０２２等指定年份）
②groupTypeName      新闻分类名称（详细页所传的gdsc_group_type_name；可以留空，如果留空的话，将展示所有分类的推荐）
③showNumber        显示数量(显示几个推荐内容，如果留空将展示五条)
*/
export const morenews = (data) => post('/gdsc_news/show_recommend', data)
// GDSC举办单位分类查询
export const grouptype = (data) => post('/gdsc_organizer/group_type', data)
// GDSC举办详情
export const details = (data) => post('/gdsc_organizer/details', data)
// 参赛选手分类查询
export const participate = (data) => post('/gdsc_participate/group_type', data)
// 参赛选手列表
export const particlist = (data) => post('/gdsc_participate/new_list', data)
// 参赛选手详细
export const particdetails = (data) => post('/gdsc_participate/details', data)
// 获取比赛场地所有分类
export const venuegroup_type = (data) => post('/gdsc_venue/group_type', data)
// 获取比赛详细信息
export const venuedetails = (data) => post('/gdsc_venue/details', data)
// registration块内容
export const registrationadd = (data) => post('/gdsc_registration/add', data)
// GDSC比赛结果
export const competition_result = (data) => post('/competition_result', data)