import {
  createStore
} from "vuex";

export default createStore({
  state: {
    show: ''
  },
  mutations: {},
  actions: {},
  modules: {},
});